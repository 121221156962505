










































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { StateProgram } from "@/models/StateProgram";
import {
  STATE_PROGRAM_REQUEST,
  STATE_PROGRAM_UPDATE,
  STATE_PROGRAM_CREATE,
  STATE_PROGRAM_DELETE,
  STATE_PROGRAM_UPDATED,
  STATE_PROGRAM_LOADED,
} from "@/store/actions/stateProgram";

@Component({})
export default class StateProgramsTable extends Vue {
  private form: any = {};
  private statePrograms: any[] = [];
  private stateProgramCreateForm: any = {};
  private stateProgramsHeaders = [
    {
      text: "Наименование",
      value: "name",
      align: "start",
      sortable: false,
      width: "70%",
    },
    {
      text: "Срезы",
      value: "slices",
      align: "center",
      sortable: false,
      width: "15%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  private loading: boolean = false;
  //Image preview
  private fullscreen = false;
  private imageView = false;
  private imgUrl = "";
  private search = "";
  private itemToDelete: any = {};
  private updateDialog: boolean = false;
  private createDialog: boolean = false;
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red lighten-1";
  private message = false;

  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.STATE_PROGRAM_TABLE,
        this.$store.getters.STATE_PROGRAM_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;
    if (
      this.needReload(
        this.$store.getters.STATE_PROGRAM_TABLE,
        this.$store.getters.STATE_PROGRAM_LOADED
      )
    ) {
      this.$store.dispatch(STATE_PROGRAM_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    console.log("INIT SELF");
    this.statePrograms = this.$store.getters.STATE_PROGRAM_TABLE.data;
    this.loading = false;
    console.log("loading");
  }
  //Methods
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = {} as any;
  }
  private toSlices(item) {
    const parsed = JSON.stringify(item);
    localStorage.setItem("currentStateProgram", parsed);
    localStorage.setItem("stateProgramTitle", item.name);
    this.$router.push({ name: "Slices", params: { id: item.id } });
  }
  private toCreate() {
    this.createDialog = true;
  }
  private toUpdate(item) {
    console.log("toUpdate", item);
    this.form.name = item.name;
    this.form.id = item.id;
    this.updateDialog = true;
  }
  private createItem() {
    this.loading = true;
    var converted = new FormData();
    delete this.form.id;
    Object.keys(this.form).forEach((key) => {
      if (typeof this.form[key] !== "undefined")
        converted.append(key, this.form[key]);
      else console.log("undefined:", key);
    });
    let data = converted;
    this.$store
      .dispatch(STATE_PROGRAM_CREATE, data)
      .then(() => {
        this.messageText =
          "Гос. программа: " + this.form.name + " — добавлена.";
        this.messageColor = "green lighten-1";
        this.tryToInitSelf();
        this.form.name = '';
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red lighten-1";
      })
      .finally(() => {
        this.message = true;
        this.loading = false;
      });
  }
  private updateItem() {
    this.loading = true;
    var converted = new FormData();
    Object.keys(this.form).forEach((key) => {
      if (typeof this.form[key] !== "undefined")
        converted.append(key, this.form[key]);
      else console.log("undefined:", key);
    });
    let id = +this.form.id;
    let data = {
      data: converted,
      id: id,
    };
    delete this.form.id;

    this.$store
      .dispatch(STATE_PROGRAM_UPDATE, data)
      .then(() => {
        this.messageText =
          "Гос. программа: " + this.form.name + " — изменена.";
        this.messageColor = "green lighten-1";
        this.tryToInitSelf();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red lighten-1";
      })
      .finally(() => {
        this.message = true;
        this.loading = false;
        this.updateDialog = false;
      });
  }
  private deleteItem(item: any, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log(item);
      console.log("Delete!");
      this.$store.dispatch(STATE_PROGRAM_DELETE, item.id).then(() => {
        this.deleteSnackbar = true;
        this.itemToDelete = {} as any;
        this.deleteDisabled = false;
        this.tryToInitSelf();
      });
      this.deleteDialog = false;
    }
  }
  //Hooks
  private mounted() {
    this.tryToInitSelf();
  }
  private nameSearch(value, search, item): boolean {
    return (
      value != null &&
      search != null &&
      typeof value === "string" &&
      value
        .toString()
        .toLocaleLowerCase()
        .indexOf(search.toString().toLocaleLowerCase()) !== -1
    );
  }
}
